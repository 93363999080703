<template>
	<v-form
		ref="form"
		v-model="valid">
		<v-row>
			<v-col cols="12">
				<v-text-field
					label="Main Value"
					v-model.number="form.main_value"
					type="number"
					suffix="in credits"
					:rules="[rules.required]" />
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Side Value"
					v-model.number="form.side_value"
					type="number"
					suffix="in credits"
					:rules="[rules.minMaxValue(0)]" />
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Z"
					v-model.number="form.z"
					type="number"
					suffix="in credits"
					:rules="[rules.minMaxValue(0)]" />
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Next Z"
					v-model.number="form.next_z"
					type="number"
					suffix="in credits"
					:rules="[rules.minMaxValue(0)]" />
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import { rules as defaultRules } from "../../../plugins/rules.ts";

export default {
	props: {
		value: {
			type: Object,
		},
	},

	data() {
		return {
			valid: false,

			rules: {
				...defaultRules,
			},
		};
	},
	computed: {
		form() {
			return this.value;
		},
	},

	watch: {
		form: {
			handler(newVal) {
				const valid = this.$refs.form.validate();
				this.$emit("input", [newVal, valid]);
			},
			deep: true,
		},
	},
};
</script>

<style></style>
