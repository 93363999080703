var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Main Value",
                  type: "number",
                  suffix: "in credits",
                  rules: [_vm.rules.required],
                },
                model: {
                  value: _vm.form.main_value,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "main_value", _vm._n($$v))
                  },
                  expression: "form.main_value",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Side Value",
                  type: "number",
                  suffix: "in credits",
                  rules: [_vm.rules.minMaxValue(0)],
                },
                model: {
                  value: _vm.form.side_value,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "side_value", _vm._n($$v))
                  },
                  expression: "form.side_value",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Z",
                  type: "number",
                  suffix: "in credits",
                  rules: [_vm.rules.minMaxValue(0)],
                },
                model: {
                  value: _vm.form.z,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "z", _vm._n($$v))
                  },
                  expression: "form.z",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Next Z",
                  type: "number",
                  suffix: "in credits",
                  rules: [_vm.rules.minMaxValue(0)],
                },
                model: {
                  value: _vm.form.next_z,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "next_z", _vm._n($$v))
                  },
                  expression: "form.next_z",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }